import React from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import styles from "./phoneCard.module.css";
import { fixEncoding } from "../../utils/fixEncoding";

const PhoneCard = ({ phone }) => {
  const firstImg = phone.resimler.split(",")[0];

  return (
    <Link
      to={`/phoneDetail/id=${phone.id}`}
      state={phone}
      className={styles["link"]}
    >
      <div className={styles["phone-card"]}>
        <div className={styles["image"]}>
          <img
            src={`https://admin.atcphonemarkt.com/phone-images/${firstImg}`}
            alt="phone-img"
            className={styles["phone-img"]}
          />
        </div>
        <div className="d-flex w-100 flex-column py-1">
          <div className={styles["phone-baslik"] + " text-start mx-2"}>
            {phone.baslik && fixEncoding(phone.baslik)}
          </div>
          <hr className={styles["hr"]} />
          <div className={styles["phone-model"] + " text-start d-flex mx-2"}>
            {phone.model} - {phone.depolama}
            <div className="text-start ms-auto">
              <NumericFormat
                value={phone.fiyat}
                displayType="text"
                thousandSeparator="."
                decimalSeparator="false"
                suffix="₺"
                className={styles["fiyat"]}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PhoneCard;
